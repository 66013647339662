import React from 'react';
import './Footer.css'; // Import CSS for styling
import Logo from '../../assets/pictures/GPTLeadsLogo.png'
import GithubLogo from '../../assets/pictures/icons8-github.svg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={Logo} alt="GPT Leads Logo" className="footer-logo" />
        <h3 className="brand-name">GPT Leads</h3>
      </div>
      <div className="footer-bottom">
        <p className="copyright">
          &copy; {new Date().getFullYear()} GPT Leads. All rights reserved.
        </p>
        {/* <div className="developer">
          <span>Developed by </span>
          <a href="https://github.com/MichaelTolulope" target="_blank" rel="noopener noreferrer">
            <img src={GithubLogo} alt="GitHub" className="github-logo" />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
