import AdsContainer from '../ads-container/AdsContainer'
import Footer from '../footer/Footer'
import Search from '../google_search-components/Search'
import './MainComponent.css'
export const MainComponent = () => {
  return (
    <div className='main-container'>
      <div className='brand'>
        <div className='logo'>
          <div className='outer-circle'>
            <div className='inner-circle'>
              <div className='innerMost-circle'></div>
            </div>
          </div>
        </div>
        <h1><span>GPT</span> Leads Generator</h1>
      </div>
        <AdsContainer/>
        <div className='searchComponent'>
          <Search/>
        </div>
        {/* <AdsContainer/> */}
        <Footer/>
    </div>
  )
}