import React from 'react';

const SearchResponse = ({ results, hasSearched }) => {
  const displayResults = results || [];

  return (
    <div style={{display:"flex",flexDirection:"column",alignSelf:"start",flex:"1"}}>
    <div className={`${displayResults.length > 0 ? 'resultContainer' : 'noResultsContainer'}`}>
      {displayResults.length > 0 ? (
        displayResults.map((item, index) => (
          <div key={index} className="result">
            <div className="result-inner">
              {item.pagemap && item.pagemap.cse_image && item.pagemap.cse_image[0] && (
                <div className="result-image">
                  <img
                    src={item.pagemap.cse_image[0].src}
                    alt={item.title}
                  />
                </div>
              )}
              <div>
                <h2 className="result-title">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                </h2>
                <div className="result-link">
                  {item.displayLink}
                </div>
                <p className="result-snippet">
                  {item.snippet}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : hasSearched ? (
        <p className="no-results-message">No results found</p>
      ) : null}
    </div>
    </div>
  );
};

export default SearchResponse;
