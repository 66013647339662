
import { useEffect, useState } from 'react';
import './App.css';
import { MainComponent } from './components/main-component/MainComponent';
import Loader from './pages/Loader/Loader';
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='main-container'>
      {isLoading ? (
        <Loader/>
      ) : (
      <MainComponent/>
      )}
    </div>
    
    // <Search/>
  );
}

export default App;
