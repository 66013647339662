import React, { useState } from 'react';
import axios from 'axios';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import Lottie from 'lottie-react';
import { FiRefreshCcw } from 'react-icons/fi';
import loadingAnimation from '../../assets/animations/loadingAnimation.json';
import networkErrorAnimation from '../../assets/animations/networkErrorAnimation.json';
import './Search.css';
import SearchResponse from './SearchResponse';
import CountdownTimer from '../countdown/CountdownTimer';
import AdComponent from '../ads-component/AdComponent';
import EasyBuy from '../../assets/gifs/BUY-NOW-PAY-LATER_GIF-2.gif';
import Modal from '../modal/Modal';

const queryClient = new QueryClient();

const fetchSearchResults = async (query) => {
  const API_KEY = process.env.REACT_APP_GCE_API_KEY;
  const SEARCH_ENGINE_ID = process.env.REACT_APP_GSE_ID;
  const API_URL = process.env.REACT_APP_GSE_API;

  try {
    const response = await axios.get(API_URL, {
      params: {
        key: API_KEY,
        cx: SEARCH_ENGINE_ID,
        q: query,
      },
    });
    return response.data.items;
  } catch (error) {
    if (!error.response) {
      throw new Error('Network error');
    }
    throw error;
  }
};

const SearchComponent = () => {
  const [selectedSite, setSelectedSite] = useState('');
  const [query, setQuery] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);

  const { data: results, refetch, isFetching, isError, error } = useQuery(
    ['searchResults', query],
    () => fetchSearchResults(query),
    {
      enabled: !!query, // Query only when there's a query string
      onSuccess: () => {
        setIsCooldown(true);
      },
      onError: (error) => {
        if (error.message === 'Network error') {
          setHasNetworkError(true);
        }
      },
    }
  );

  const sites = ['Instagram.com', 'facebook.com', 'tiktok.com', 'daily.dev']; // Add more sites as needed
  const presetSearches = ["today's fast paced world", 'Ronaldo', 'Tech today']; // Add more queries as needed

  const handlePresetSearch = (preset, index) => {
    if (!selectedSite) {
      setShowModal(true);
      return;
    }
    const searchQuery = `site:${selectedSite} ${preset}`;
    setQuery(searchQuery);  // Set the query string
    setHasNetworkError(false);
    refetch();  // Immediately refetch the search results
  };

  const copyLinksToClipboard = () => {
    if (!results) return;
    const links = results.map((result) => result.link).join('\n');
    navigator.clipboard.writeText(links).then(() => {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 500);
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const onCooldownComplete = () => {
    setIsCooldown(false);
  };

  return (
    <div className="container">
      {showModal && (
        <Modal message="Please select a site from the dropdown." onClose={() => setShowModal(false)} />
      )}
      <div className='inner-container'>
        <div className='right-part'>
          <div className="dropdown-container">
            <select
              value={selectedSite}
              onChange={(e) => setSelectedSite(e.target.value)}
              className="dropdown"
            >
              <option value="" disabled>Select a site...</option>
              {sites.map((site, index) => (
                <option key={index} value={site}>
                  {site}
                </option>
              ))}
            </select>
          </div>
          <div className="preset-buttons">
            {presetSearches.map((preset, index) => (
              <button
                key={index}
                className="preset-button"
                onClick={() => handlePresetSearch(preset, index)}
                disabled={isCooldown}
              >
                Leads #{index + 1}
              </button>
            ))}
          </div>
          <div className='copy-container'>
            <button onClick={copyLinksToClipboard} className="copy-button">
              📋 Copy
            </button>
            {showToast && <div className="toast">Copied!</div>}
          </div>
        </div>
        <div className='middle-container'>
          <div style={{ maxHeight: "250px", width: "100%" }}>
            <AdComponent children={<img style={{ width: "100%", height: "100%" }} src={EasyBuy} alt='add gif' />} />
          </div>
          <div className='instructions'>
            <p style={{ fontWeight: "bold" }}>
              You have 30 seconds interval to search whatever you want and copy all the result links 😉
            </p>
          </div>
          <CountdownTimer cooldownSeconds={30} isCooldown={isCooldown} onCooldownComplete={onCooldownComplete} />
        </div>
      </div>
      <div className='outer-result-container'>
        <h1 style={{ color: "grey", fontWeight: "bold", position: "absolute", top: "-80px" }}>Results</h1>
        {isFetching ? (
          <div className='loadingAnimationContainer'>
            <div className="loading-animation">
              <Lottie animationData={loadingAnimation} loop={true} />
            </div>
          </div>
        ) : hasNetworkError || isError ? (
          <div className="error-container">
            {error.message === 'Network error' ? (
              <div className="network-error-animation">
                <Lottie animationData={networkErrorAnimation} loop={true} />
                <p>Network error. Please check your connection and try again.</p>
              </div>
            ) : (
              <div className="generic-error">
                <p>An error occurred. Please try again.</p>
              </div>
            )}
            <div className="reload-button-container">
              <button style={{ border: "none", background: "transparent" }} onClick={() => refetch()}>
                <FiRefreshCcw size={24} />
              </button>
            </div>
          </div>
        ) : results && results.length === 0 ? (
          <div className="no-results">
            <p>No results found from {selectedSite}.</p>
          </div>
        ) : (
          <SearchResponse results={results} />
        )}
      </div>
    </div>
  );
};

const Search = () => (
  <QueryClientProvider client={queryClient}>
    <SearchComponent />
  </QueryClientProvider>
);

export default Search;
