import AdComponent from '../ads-component/AdComponent'
import './AdsContainer.css'
import ad2 from '../../assets/pictures/GCDR.png'
import ad from '../../assets/pictures/GPTM.png'

const AdsContainer = () => {
  return (
    <div className='ad-container'>
        <AdComponent children={
          <a href="https://gcdreports.com/" target="_blank" rel="sponsored">
            <img src={ad2} alt="Ad"/>
          </a>
        }/>
        <AdComponent children={
          <a href="https://gptmastery.net/" target="_blank" rel="sponsored">
            <img src={ad} alt="Ad"/>
          </a>
        }/>
    </div>
  )
}
export default AdsContainer