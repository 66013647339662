import React, { useState, useEffect } from 'react';

const CountdownTimer = ({
  cooldownSeconds,
  onCooldownComplete,
  isCooldown
}) => {
  const [seconds, setSeconds] = useState(cooldownSeconds);

  useEffect(() => {
    let myInterval;
    if (isCooldown) {
      setSeconds(cooldownSeconds); // Reset the timer on cooldown start
      myInterval = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(myInterval);
            if (onCooldownComplete) {
              onCooldownComplete();
            }
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(myInterval);
  }, [isCooldown, cooldownSeconds, onCooldownComplete]);

  return (
    <div style={styles.timer}>
      {isCooldown ? (
        <h1 style={{ ...styles.text, color: 'red' }}>Search again in {seconds} seconds</h1>
      ) : (
        <h1 style={styles.text}>
          00 : 00
        </h1>
      )}
    </div>
  );
};

const styles = {
  timer: {
    padding: '2px 10px',
    border: '2px solid #61dafb',
    borderRadius: '10px',
    backgroundColor: '#20232a',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    display: 'inline-block',
  },
  text: {
    fontSize: '28px',
    margin: '0',
  },
};

export default CountdownTimer;
